.construction {
  display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  height: 60vh;
}

.construction img {
    border-radius: 20px;
    margin: 1em;
    width: 300px;
    height: auto;
}