.skill-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    padding-bottom: 1em;
}

.skill {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 300px;
    border-radius: 10px;
     margin: 1.5em;
    padding: 1em;
}

.skill img {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    border-radius: 4px;
}
    
@media screen and (max-width: 500px) {
    .skill {
        /* width: fit-content; */
        width: 100%;
        margin: 1em;
    }

    .skill-title {
        font-size: 1.5rem;
    }
}
