@import url(https://fonts.googleapis.com/css2?family=Ubuntu&display=swap);
body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pulse {
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

.rotate {
  -webkit-animation: rotate 4s linear infinite;
          animation: rotate 4s linear infinite;
}

.wiggle {
  -webkit-animation: wiggle 1.25s infinite;
          animation: wiggle 1.25s infinite;
}

@-webkit-keyframes rotate {
  0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      
  }
  
  100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      
  }
}

@keyframes rotate {
  0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      
  }
  
  100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
      
  }
}

@-webkit-keyframes pulse {
  0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      
  }
  50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      
  }
  100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      
  }
}

@keyframes pulse {
  0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      
  }
  50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      
  }
  100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      
  }
}

@-webkit-keyframes wiggle {
  0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
  25% {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg);
  }
  50% {
      -webkit-transform: rotate(-5deg);
              transform: rotate(-5deg);
  }
  75% {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg);
  }
  100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
}

@keyframes wiggle {
  0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
  25% {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg);
  }
  50% {
      -webkit-transform: rotate(-5deg);
              transform: rotate(-5deg);
  }
  75% {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg);
  }
  100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
}
.App {
  min-height: 100vh;
  text-align: center;
}


.App.dark {
  background: #333;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

.App.light {
  transition: all 0.2s ease-in-out;
}


.home {

}

.home .header {
    display: flex;
    align-items: end;
    justify-content: center;
    padding-top: 50px;
}

.profile-photo-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px 100px 0px 100px;
  /* border: 2px solid #fff; */
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 20px 30px 20px 20px;
  box-shadow: rgba(96, 96, 107, 0.2) 0px 7px 29px 0px;
}

.profile-photo {
    margin-top: 10px;
    width: 120px;
    height: auto;
}

/* Body Styles */
.body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.introduction {
    margin-top: 50px;
    width: 65%;
    line-height: 2em;
    font-size: x-large;
    text-align: center;
    
}

.tech-info {
    margin-top: 50px;
    width: 65%;

}

@media screen and (max-width: 680px) {
  .home .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 1em;
    line-height: 2em;
    font-size: large;
}


  .introduction {
    width: 90%;
    /* text-align: left; */
    
  }
}
.skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dark-skills {
    /* background-color: #444; */
    border-radius: 20px;
    
}

@media screen and (max-width: 680px) {
    .skills {
      width: -webkit-min-content;
      width: min-content;
      flex-direction: column;
    }
  }


.skill-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    padding-bottom: 1em;
}

.skill {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 300px;
    border-radius: 10px;
     margin: 1.5em;
    padding: 1em;
}

.skill img {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    border-radius: 4px;
}
    
@media screen and (max-width: 500px) {
    .skill {
        /* width: fit-content; */
        width: 100%;
        margin: 1em;
    }

    .skill-title {
        font-size: 1.5rem;
    }
}

.construction {
  display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  height: 60vh;
}

.construction img {
    border-radius: 20px;
    margin: 1em;
    width: 300px;
    height: auto;
}
.download-btn {
  font-weight: 600;
  font-size: larger;
  border: 2px solid #fff;
  padding: 0.5rem;
  border-radius: 4px;
}

.job-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 2rem;
}
.job {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    
    padding: 1rem;
    /* box-shadow: rgba(97, 97, 97, 0.2) 0px 8px 24px; */
    text-align: left;
}
.job-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
    margin: 5px;
}
.job-company {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: left;
    margin: 5px;
    font-style: italic;
}

.responsibilities {
    margin-bottom: 50px;
    list-style: none;
    text-align: left;
}

.responsibilities li::before {
    content: "-";
    margin-right: 10px;
}


.education-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    
    padding: 1rem;
    /* box-shadow: rgba(97, 97, 97, 0.2) 0px 8px 24px; */
}

.education, .work-skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    
    padding: 1rem;
    /* box-shadow: rgba(97, 97, 97, 0.2) 0px 8px 24px; */
    text-align: left;
}



.project-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: left;
  grid-gap: 1rem;
  width: 60vw;
  padding: 2rem;
  margin: 0 auto;
  /* box-shadow: rgba(97, 97, 97, 0.2) 0px 8px 24px; */
  border-radius: 20px;
}

.project-container:not(:last-child) {
  margin-bottom: 2rem;
}

.project-image {
  width: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  
}

.project-url {
    color: #00bcd4;
    text-decoration: underline;
}

@media screen and (max-width: 680px) {

  .portfolio {
    padding: 0.5rem;
  }
  .project-container {
    grid-template-columns: 1fr;

  }
  .project-image {
    width: 100%;
  }
}

@media screen and (max-width: 1350px) {

  .project-container {
    grid-template-columns: 1fr;
  }

}
  
.navbar {
  padding: 10px 20px;
  background: #16BFFD;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #CB3066, #16BFFD); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}

.navbar nav {
  display: flex;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  position: relative;
}
.navbar a.brand {
  margin-right: auto;
  color: #fff;
  text-decoration: none;
  font-size: 1em;
}

.nav-icon {
  width: 30px;
  height: 30px;
}

.navbar a:not(:first-child) {
  color: #eee;
  text-decoration: none;
  margin-left: 20px;
  padding: 8px;
  border-radius: 4px;
  height: 100%;
  font-weight: 500;
  transition: font-size .3s ease-in-out;
  position:relative;
  
}
.navbar a:not(:first-child):hover {
  font-weight: 700;
  font-size: larger;
  transition: font-weight .3s ease-in-out;
}

.navbar a:not(.brand).active {
  font-weight: 600;
  font-size: larger;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 4px;

}

.dark-mode-icon {
  width: 30px;
  height: 30px;
  margin-left: 20px;
  cursor: pointer;
}

.flip-dark {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transition: ease-in-out 0.3s;
}

.flip-light {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transition: ease-in-out 0.3s;
}

.dark-nav-theme {
background: #0F2027;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
box-shadow: rgba(180, 180, 180, 0.2) 0px 7px 29px 0px;
}


.dark-nav-theme a:not(:first-child) {
  transition: color .03s ease;
  color: #888;
}

.dark-nav-theme a:not(.brand).active {
  border: 2px solid #444;
  color: #e6e6e6;
}

/* Media query to resize the brand */
@media screen and (max-width: 680px) {
  .navbar a.brand {
    font-size: .75rem;
  }
   
  .navbar a:not(.brand).active,
  .navbar a:not(:first-child):hover {
    font-size: medium;
  }
  
  .navbar a:not(:first-child)
   {
    margin-left: 10px;
    font-size: small;
  }

  .nav-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .nav-icon {
    height: 20px;
    width: 20px;
  }
}

@media screen and (max-width: 500px) {
  .nav-icons {
    position: absolute;
    left: -30px;
    top: 0px;
  }

  .navbar a.brand {
    font-size: .75rem;
    text-align: left;
    padding-top: 25px;
    margin-left: -10px;
  }

  .navbar a:not(.brand):first{
    margin-left: -10px;
  }
  
  .navbar a:not(.brand).active,
  .navbar a:not(:first-child):hover {
    font-size: small;
  }
  
  .navbar a:not(:first-child)
   {
    margin-left: 5px;
    font-size: small;
  }
}   
  




