.project-container {
  display: grid;
  grid-template-columns: 1fr 2fr;
  text-align: left;
  grid-gap: 1rem;
  width: 60vw;
  padding: 2rem;
  margin: 0 auto;
  /* box-shadow: rgba(97, 97, 97, 0.2) 0px 8px 24px; */
  border-radius: 20px;
}

.project-container:not(:last-child) {
  margin-bottom: 2rem;
}

.project-image {
  width: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.project-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  
}

.project-url {
    color: #00bcd4;
    text-decoration: underline;
}

@media screen and (max-width: 680px) {

  .portfolio {
    padding: 0.5rem;
  }
  .project-container {
    grid-template-columns: 1fr;

  }
  .project-image {
    width: 100%;
  }
}

@media screen and (max-width: 1350px) {

  .project-container {
    grid-template-columns: 1fr;
  }

}
  