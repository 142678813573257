.home {

}

.home .header {
    display: flex;
    align-items: end;
    justify-content: center;
    padding-top: 50px;
}

.profile-photo-frame {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px 100px 0px 100px;
  /* border: 2px solid #fff; */
  width: 120px;
  height: 120px;
  overflow: hidden;
  margin: 20px 30px 20px 20px;
  box-shadow: rgba(96, 96, 107, 0.2) 0px 7px 29px 0px;
}

.profile-photo {
    margin-top: 10px;
    width: 120px;
    height: auto;
}

/* Body Styles */
.body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.introduction {
    margin-top: 50px;
    width: 65%;
    line-height: 2em;
    font-size: x-large;
    text-align: center;
    
}

.tech-info {
    margin-top: 50px;
    width: 65%;

}

@media screen and (max-width: 680px) {
  .home .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 1em;
    line-height: 2em;
    font-size: large;
}


  .introduction {
    width: 90%;
    /* text-align: left; */
    
  }
}