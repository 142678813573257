.App {
  min-height: 100vh;
  text-align: center;
}


.App.dark {
  background: #333;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

.App.light {
  transition: all 0.2s ease-in-out;
}

