.skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dark-skills {
    /* background-color: #444; */
    border-radius: 20px;
    
}

@media screen and (max-width: 680px) {
    .skills {
      width: min-content;
      flex-direction: column;
    }
  }

